import React from 'react'
import SliceSection from './slice_section'

const SponsorLevelSlice = ({ slice }) => {
  return (
    <SliceSection slice={slice} className="sponsor-level-slice">
      <div className="grid-x grid-padding-x">
        <div className="cell small-12 medium-8 medium-offset-2">
          <h2>Sponsorships</h2>
          {slice.items.map((obj) => {
            return (
              <div className="sponsor_level" key={obj.level_title.text}>
                <h3>
                  {obj.level_title.text}
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: obj.level_description.html,
                  }}
                ></div>
                <a href={slice.primary.registration_link.url} target={slice.primary.registration_link.target} className="button small accent">Sponsor &rarr;</a>
              </div>
            )
          })}
        </div>
      </div>
    </SliceSection>

  )
}

export default SponsorLevelSlice