import React from 'react'
import SliceSection from './slice_section'
const ButtonSlice = ({ slice }) => {

  if (!slice.primary?.destination?.url) {
    return null
  }

  return (
    <SliceSection slice={slice} className="button-slice">
      <div className="grid-x grid-padding-x">
        <div className="cell small-12 medium-8 medium-offset-2">
          <div className="slice-button text-center down-1 bot-1">
            <a href={slice.primary?.destination?.url} target={slice.primary?.destination?.target} className={`button ${slice.primary?.color}`} >{slice.primary?.text?.text} </a>
          </div>
        </div>
      </div>
    </SliceSection>
  )
}

export default ButtonSlice