import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SliceSection from './slice_section'
import clsx from 'clsx'

const SbsSlice = ({ slice }) => {

  return (
    <SliceSection slice={slice} className="sbs-slice">
      <div className='grid-x grid-padding-x'>
        <div className={clsx('cell small-12 large-6', slice.primary?.image_position === 'right' && 'small-order-2')}>
          <GatsbyImage image={getImage(slice.primary?.sbs_image)} alt={slice.primary?.sbs_image?.alt} objectFit='contain' />
        </div>
        <div className={clsx('cell small-12 large-6', slice.primary?.image_position === 'right' && 'small-order-1')}>
          <div
            dangerouslySetInnerHTML={{ __html: slice.primary?.sbs_body?.html }}
          ></div>
        </div>
      </div>
    </SliceSection>
  )
}

export default SbsSlice