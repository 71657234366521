
import * as React from 'react'
import {
  PrismicPreviewProvider
} from 'gatsby-plugin-prismic-previews'

import PrismicPreviewConfig from './prismic-preview-config'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={PrismicPreviewConfig}
  >
    {element}
  </PrismicPreviewProvider>
)