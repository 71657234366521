import React from 'react'
import TextSlice from '../slices/text_slice'
import ButtonSlice from '../slices/button_slice'
import PositionSlice from '../slices/position_slice'
import SponsorLevelSlice from '../slices/sponsor_level_slice'
import YoutubeSlice from '../slices/youtube_slice'
import DividerSlice from '../slices/divider_slice'
import ImageSlice from '../slices/image_slice'
import SbsSlice from '../slices/side_by_side_slice'
import SideBySideButtonsSlice from '../slices/side_by_side_buttons_slice'

const SliceZone = ({ slices }) => {
  const sliceComponents = {
    text: TextSlice,
    button: ButtonSlice,
    position: PositionSlice,
    sponsor_level: SponsorLevelSlice,
    youtube_video: YoutubeSlice,
    divider: DividerSlice,
    image: ImageSlice,
    side_by_side_with_image: SbsSlice,
    sbs_buttons: SideBySideButtonsSlice
  }

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return null
  })
}

export default SliceZone