import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import CallToAction from "./cta_general"
import { Link } from "gatsby"

const Footer = () => (
  <footer>
    <div id="contact">
      <CallToAction />
    </div>
    <div className="lower-footer">
      <a href="https://www.guidestar.org/profile/73-1603851" rel="noreferrer" target="_blank">
        <StaticImage src="../images/candid.png" alt="Guidestar Certified" height={80} /></a>
      <a href="https://www.omegahomenetwork.org/" rel="noreferrer" target="_blank">
        <StaticImage src="../images/omega.png" alt="Omega Home Network Member" height={80} /></a>
      <p className="copyright">&copy; {new Date().getFullYear()} Clarehouse</p>
      <Link to="/privacy-policy">Privacy Policy</Link>
      <p className="tax-deductible">Clarehouse is a 501(c)(3) nonprofit organization. All donations are tax deductible to the extent allowed by law.</p>
    </div>
  </footer>
)

export default Footer
