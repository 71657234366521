import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SliceZone from "../components/slice_zone"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

class pageTemplate extends React.Component {
  render() {
    const page = get(this.props, "data.prismicPage.data")

    return (
      <Layout location={this.props.location}>
        <Seo
          title={page.title.text}
          description=""
          pathname={`/${page.uid}/`}
        />
        <div className="page-contain">
          {page.image?.gatsbyImageData &&
            <div className="banner">
              <GatsbyImage image={getImage(page.image)} alt={page.title.text} />
            </div>
          }
          <section>
            <div className="grid-x grid-padding-x">
              <div className="cell small-12 medium-8 medium-offset-2">
                <h1>{page.title.text}</h1>
              </div>
            </div>
          </section>
          <div>
            {page.body && <SliceZone slices={page.body} />}
          </div>
        </div>
      </Layout>
    )
  }
}

export default withPrismicPreview(pageTemplate)

export const pageQuery = graphql`
query PageBySlug($uid: String!) {
  prismicPage(uid: {eq: $uid}) {
    _previewable
    data {
      title {
        text
      }
      body {
        ... on PrismicPageDataBodyText {
          slice_type
          primary {
            style
            slice_body {
              html
            }
            padding_top
            padding_bottom
            background_color
            custom_background_color
            custom_text_color
          }
        }
        ... on PrismicPageDataBodyButton {
          slice_type
          primary {
            color
            background_color
            custom_background_color
            destination {
              url
              target
            }
            text {
              text
            }
          }
        }
        ... on PrismicPageDataBodyImage {
          slice_type
          primary {
            padding_top
            padding_right
            padding_left
            padding_bottom
            float
            alignment
            alt {
              text
            }
            file {
              gatsbyImageData(layout: CONSTRAINED)
              alt
            }
          }
        }
        ... on PrismicPageDataBodyPosition {
          slice_type
          primary {
            background_color
            section_title {
              text
            }
          }
          items {
            application_link {
              target
              url
            }
            position_description {
              html
            }
            position_title {
              text
            }
            button_text {
              text
            }
          }
        }
        ... on PrismicPageDataBodyYoutubeVideo {
          id
          primary {
            background_color
            embed_id {
              text
            }
          }
          slice_type
        }
        ... on PrismicPageDataBodyDivider {
          id
          primary {
            background_color
          }
          slice_type
        }
        ... on PrismicPageDataBodySideBySideWithImage {
          id
          slice_type
          primary {
            sbs_body {
              html
            }
            sbs_image {
              gatsbyImageData
              alt
            }
            background_color
            custom_background_color
            custom_text_color
            padding_bottom
            padding_top
            image_position
          }
        }
        ... on PrismicPageDataBodySbsButtons {
          id
          primary {
            background_color
            color1
            color2
            custom_background_color
            destination1 {
              target
              url
            }
            destination2 {
              target
              url
            }
            text1 {
              text
              html
            }
            text2 {
              html
              text
            }
          }
          slice_label
          slice_type
        }
      }
      image {
        gatsbyImageData(width: 1920, height: 650, placeholder: BLURRED)
        alt
      }
    }
    uid
    parent {
      ... on PrismicPage {
        id
        uid
      }
    }
  }
}
`