import React from "react"
import { FaPhoneAlt, FaFax, FaBuilding } from 'react-icons/fa';
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "./contact_form";


const CallToAction = () => (
  <section className="cta">
    <div className="grid-x small-up-1 medium-up-2">
      <div className="cell">
        <div className="form-contain">
          <h2>Contact Us</h2>
          <ContactForm />
        </div>
      </div>
      <div className="cell">
        <div className="contact-info">
          <StaticImage src="../images/building.png" objectFit="contain" alt="Clarehouse Home in Tulsa, OK" />
          <p className="mission">We embrace death and dying as a normal part of life and provide, at no cost to guests and families, the support people need to make the most of every moment.</p>
          <p>
            <FaBuilding />
            <a href="https://goo.gl/maps/CxVDwXaLvPRad7Zv6" target="_blank" rel="noreferrer">
              <b>Clarehouse</b> <br />
              7617 S Mingo Rd <br />
              Tulsa, Oklahoma 74133
            </a>
          </p>
          <p>
            <FaPhoneAlt />&nbsp; < a href="tel:9188936150">918-893-6150</a>
          </p>
          <p>
            <FaFax />&nbsp; 918-893-6152
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default CallToAction
