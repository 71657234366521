

import React from "react"


const ContactForm = () => (
  <form
    method="post"
    netlify-honeypot="username"
    data-netlify="true"
    name="Contact Form"
    action="/thank-you"
    className="contact_form"
  >

    <input type="hidden" name="username" />
    <input type="hidden" name="form-name" value="Contact Form" />
    <label>
      Your Name
      <input type="text" name="name" id="name" required />
    </label>
    <label>
      Your Email
      <input type="email" name="email" id="email" required />
    </label>
    <label>
      Your Phone
      <input type="text" name="phone" id="phone" required />
    </label>

    <label>
      How Can We Help You?
      <textarea rows="5" name="description" id="description" />
    </label>

    <button type="submit" className="button">
      Submit &rarr;
    </button>
  </form>
)

export default ContactForm