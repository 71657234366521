import PageTemplate from './src/templates/page'
import EventTemplate from './src/templates/event'
import JobTemplate from './src/templates/job'
import StaffTemplate from './src/templates/staff'
import BoardMemberTemplate from './src/templates/board'

const LinkResolver = (doc) => {
  switch (doc.type) {
    case 'event':
      return `/events/${doc.uid}`
    case 'job':
      return `/jobs/${doc.uid}`
    case 'staff':
      return `/board-and-staff/${doc.uid}`
    case 'board_member':
      return `/board-and-staff/${doc.uid}`
    case 'page':
      return `/${doc.uid}`
    default:
      return `/${doc.uid}`
  }
}

export const PrismicPreviewConfig = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver: LinkResolver,
    componentResolver: {
      page: PageTemplate,
      event: EventTemplate,
      job: JobTemplate,
      staff: StaffTemplate,
      board_member: BoardMemberTemplate,
    },
  },
]



export default PrismicPreviewConfig