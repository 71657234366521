import React from 'react'
import SliceSection from './slice_section'
const TextSlice = ({ slice }) => {
  return (
    <SliceSection slice={slice} className="text-slice">
      <div className="grid-x grid-padding-x">
        <div className="cell small-12 medium-8 medium-offset-2">
          <div
            style={{
              paddingTop: slice.primary.padding_top,
              paddingBottom: slice.primary.padding_bottom,
              color: slice.primary.custom_text_color,
            }}
            className="text-slice-inner-text"
            dangerouslySetInnerHTML={{ __html: slice.primary.slice_body.html }}
          ></div>
        </div>
      </div>
    </SliceSection>
  )
}

export default TextSlice