import * as React from "react"
import Navigation from "./navigation"
import Footer from "./footer"
import "../styles/application.scss"

const Layout = ({ children }) => {
  return (
    <div>
      <Navigation />
      <main className="primary-content">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
