import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

class BoardTemplate extends React.Component {
  render() {
    const mem = get(this.props, "data.prismicBoardMember.data")

    return (
      <Layout location={this.props.location}>
        <Seo
          title={mem.name.text}
          description=""
          pathname={`/board-and-staff/${mem.uid}/`}
        />
        <div className="page-contain">
          <section className="staff_member_full">
            <div className="grid-x grid-padding-x">
              <div className="cell small-12 medium-3 medium-offset-1">
                <GatsbyImage image={getImage(mem.headshot)} alt={mem.name.text} />
                <span className="job_title">Board {mem.board_title.text}</span>
              </div>
              <div className="cell small-12 medium-7">
                <h1>{mem.name.text}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: mem.bio.html,
                  }}
                ></div>
              </div>
            </div>
          </section>

        </div>
      </Layout>
    )
  }
}

export default withPrismicPreview(BoardTemplate)

export const pageQuery = graphql`
query BoardTemplateQuery($uid: String!) {
  prismicBoardMember(uid: {eq: $uid}) {
    _previewable
    data {
      bio {
        html
      }
      headshot {
        gatsbyImageData(width: 350, height: 350, placeholder: BLURRED,
          imgixParams: {crop: "faces,top, left", fit: "crop", auto: "compress"})

      }
      board_title {
        text
      }
      name {
        text
      }
    }
    uid
  }
}
`