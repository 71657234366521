import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SliceSection from './slice_section'

const ImageSlice = ({ slice }) => {
  let image = slice.primary || {}

  let alignment = "text-" + image.alignment
  return (
    image.file &&
    <SliceSection slice={slice} className="image-slice">
      <div className={alignment}>
        <GatsbyImage image={getImage(image.file)} alt={image.file?.alt || image.alt?.text} objectFit='contain' />
      </div>
    </SliceSection>
  )
}

export default ImageSlice