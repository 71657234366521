import React from 'react'
import SliceSection from './slice_section'
const DividerSlice = ({ slice }) => {
  return (
    <SliceSection slice={slice} className="divider-slice">
      <div className="grid-x grid-padding-x">
        <div className="cell small-12 medium-8 medium-offset-2">
          <div className="slice-divider text-center down-1 bot-1">
            <hr></hr>
          </div>
        </div>
      </div>
    </SliceSection>
  )
}

export default DividerSlice