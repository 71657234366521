import React from 'react'
import SliceSection from './slice_section'

const YoutubeSlice = ({ slice }) => {
  return (
    <SliceSection slice={slice} className="youtube-slice">
      <div className="grid-x grid-padding-x">
        <div className="cell small-12 medium-8 medium-offset-2">
          <div className="video-responsive">
            <iframe
              width="853"
              height="480"
              src={`https://www.youtube.com/embed/${slice.primary.embed_id.text}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Youtube Video"
            />
          </div>
        </div>
      </div>
    </SliceSection>

  )
}

export default YoutubeSlice