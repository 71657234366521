import React from 'react'

const SliceSection = ({ slice, children, className }) => {

  let paddingTop = slice.primary?.padding_top ? slice.primary.padding_top : '20px'
  let paddingBottom = slice.primary?.padding_bottom ? slice.primary.padding_bottom : '20px'

  const styles = {
    backgroundColor: slice.primary.custom_background_color,
    paddingTop: paddingTop,
    paddingBottom: paddingBottom,
  }

  return (
    <section className={`${slice.primary?.background_color || ''} ${className} ${slice.primary?.style || ''} ${slice.primary?.custom_text_color ? 'custom-color' : 'no-custom-color'}`} style={styles}>
      {children}
    </section>
  )
}

export default SliceSection