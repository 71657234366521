import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SliceZone from "../components/slice_zone"
import moment from "moment"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

class EventTemplate extends React.Component {
  render() {
    const event = get(this.props, "data.prismicEvent.data")

    return (
      <Layout location={this.props.location}>
        <Seo
          title={event.title.text}
          description=""
          pathname={`/${event.uid}/`}
        />
        <div className="page-contain event-contain">
          <div className="banner">
            <GatsbyImage image={getImage(event.image)} alt={event.title.text} />
          </div>
          <section>
            <div className="grid-x grid-padding-x">
              <div className="cell small-12 medium-8 medium-offset-2">
                <h1>{event.title.text}</h1>
                <span className="event_date">
                  {moment(event.event_date).format('MMMM Do, YYYY')}
                </span>
              </div>
            </div>
          </section>
          <div>
            {event.body && <SliceZone slices={event.body} />}
          </div>
        </div>
      </Layout>
    )
  }
}

export default withPrismicPreview(EventTemplate)

export const pageQuery = graphql`

query EventBySlug($uid: String!) {
  prismicEvent(uid: {eq: $uid}) {
    _previewable
    data {
      title {
        text
      }
      body {
        ... on PrismicEventDataBodySponsorLevel {
          id
          primary {
            background_color
            registration_link {
              url
              target
            }
          }
          items {
            level_description {
              html
            }
            level_title {
              text
            }
          }
          slice_type
        }
        ... on PrismicEventDataBodyText {
          id
          primary {
            background_color
            padding_bottom
            padding_top
            slice_body {
              html
            }
            style
          }
          slice_type
        }
        ... on PrismicEventDataBodyImage {
          slice_type
          primary {
            padding_top
            padding_right
            padding_left
            padding_bottom
            float
            alignment
            file {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        ... on PrismicEventDataBodyButton {
          id
          primary {
            background_color
            color
            destination {
              target
              url
            }
            text {
              text
            }
          }
          slice_type
        }
      }
      image {
        gatsbyImageData(
          width: 1920
          height: 650
          placeholder: BLURRED
          layout: CONSTRAINED
          backgroundColor: "#FFF"
          imgixParams: {fit: "clamp"}
        )
      }
      event_date
    }
    uid
    parent {
      ... on PrismicPage {
        id
        uid
      }
    }
  }
}

`