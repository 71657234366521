import React from 'react'
import SliceSection from './slice_section'
const PositionSlice = ({ slice }) => {
  return (
    <SliceSection slice={slice} className="position-slice">
      <div className="grid-x grid-padding-x">
        <div className="cell small-12 medium-8 medium-offset-2">
          {slice.items.map((obj) => {
            return (
              <div className="position" key={obj.position_title.text}>
                <h3>
                  {obj.position_title.text}
                  {slice.primary.section_title && slice.primary.section_title.text?.length > 0 &&
                    <span className="tag">{slice.primary.section_title.text}</span>}
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: obj.position_description.html,
                  }}
                ></div>
                <a href={obj.application_link ? obj.application_link.url : "#"} target={obj.application_link ? obj.application_link.target : "_self"} className="button small accent">{obj.button_text.text ? obj.button_text.text + " →" : "Apply Now →"}</a>
              </div>
            )
          })}
        </div>
      </div>
    </SliceSection>

  )
}

export default PositionSlice