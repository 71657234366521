import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo"
import Layout from "../components/layout";
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'


function JobPage({ data }) {
  const job = data.prismicJobs.data;
  const ytid = youtube_parser(job.video_url.url);
  return (
    <Layout>
      <Seo title={job.title.text} description={job.excerpt.text} />
      <section>
        <div className="grid-x grid-padding-x down-3 bot-3">

          <div className="cell small-12 large-6 large-offset-1">
            {job.video_url?.url && <div className="video-responsive">
              <iframe
                src={`https://www.youtube.com/embed/${ytid}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Youtube Video"
              />
            </div>}
            <h1 className="down-half">{job.title.text}</h1>
            <div dangerouslySetInnerHTML={{ __html: job.body.html }} />
            <a href={job.application_link?.url} target="_blank" rel="noopener noreferrer" className="button accent show-for-large">Apply Online &rarr;</a>

          </div>
          <div className="cell small-12 large-4">
            <a href={job.application_link?.url} target="_blank" rel="noopener noreferrer" className="button accent expanded">Apply Online &rarr;</a>

            <div className="reasons">
              <h2>Reasons to Work At Clarehouse</h2>
              <ul>
                {job.reasons?.map(({ reason }) => {
                  return (
                    <li key={reason.text}>{reason.text}</li>
                  )
                })}
              </ul>
            </div>
            <div className="about-clarehouse">
              <h2>About Clarehouse</h2>
              <div dangerouslySetInnerHTML={{ __html: job.about.html }} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPrismicPreview(JobPage)

function youtube_parser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
}

export const pageQuery = graphql`
query JobBySlug($uid: String!) {
  prismicJobs(uid: {eq: $uid}) {
    _previewable
    data {
      about {
        html
      }
      application_link {
        url
      }
      body {
        html
      }
      video_url {
        url
      }
      excerpt {
        text
      }
      reasons {
        reason {
          text
        }
      }
      title {
        text
      }
    }
  }
}
`;