import React from 'react'
import SliceSection from './slice_section'
const SideBySideButtonsSlice = ({ slice }) => {

  const data = slice.primary || {}

  if (!data?.destination1?.url || !data?.destination2?.url) {
    return null
  }

  return (
    <SliceSection slice={slice} className="side-by-side-buttons-slice">
      <div className="grid-x grid-padding-x">
        <div className="cell small-12 medium-8 medium-offset-2">
          <div className="text-center down-1 bot-1 spread-1">
            <a href={data?.destination1?.url} target={data?.destination1?.target} className={`of-group button ${data?.color1}`} >{data?.text1?.text} </a>
            <a href={data?.destination2?.url} target={data?.destination2?.target} className={`of-group button ${data?.color2}`} >{data?.text2?.text} </a>
          </div>
        </div>
      </div>
    </SliceSection>
  )
}

export default SideBySideButtonsSlice